import {ReactElement} from 'react';
import {useRouter} from "next/router";
import {useMobile} from '@/lib/hooks/useMobile';
import {useTablet} from '@/lib/hooks/useTablet';

import {ComponentProps} from '@components/ComponentProps';
import InfoBlockIcon from '@components/InfoBlockIcon';

import {InfoBlockFragment_infoBlockLinks} from '@components/InfoBlock/__generated__/InfoBlockFragment';

import {StyledInfoBlockLink} from './InfoBlockLink.styles';

export type InfoBlockLinkProps = InfoBlockFragment_infoBlockLinks & {
  mode: string;
};

export default function InfoBlockLink({
  icon,
  url,
  mode
}: ComponentProps<InfoBlockLinkProps>): ReactElement {
  const isMobile = useMobile();
  const isTablet = useTablet();
  const router = useRouter();

  return (<StyledInfoBlockLink
      className={`${mode}${(isMobile || !isTablet) ? ' even' : ''}`}
      onClick={() => router && router.push(url.url)}>
    <InfoBlockIcon icon={icon} />
    <a href={url.url} target={url.target ? url.target : undefined}>{url.title}</a>
  </StyledInfoBlockLink>);
}
