import {ReactElement} from 'react';
import {useTheme} from 'styled-components';

import {StyledPatterns} from './InfoBlockPatterns.styles';
// Component
export default function InfoBlockPatterns(): ReactElement {
  const { key } = useTheme();

  return (
    key !== 'zsl' ?
    <StyledPatterns>
      <img loading="lazy" alt="" className={`pattern-${key}-left pattern left`} src={`/InfoBlock/pattern-${key}-left.svg`} />
      <img loading="lazy" alt="" className={`pattern-${key}-right pattern right`} src={`/InfoBlock/pattern-${key}-right.svg`} />
    </StyledPatterns>
    :
    <></>
  );
}
