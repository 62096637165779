import styled from 'styled-components';
import {colors, mediaQueries} from '@/theme/lib';

export const StyledPatterns = styled('div')`
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  .pattern {
    position: absolute;
    width: 200px;
    height: 200px;
    rect {
      width: ${(props) => (props.theme.key == 'whipsnade' ? '200px' : undefined )};
      height: ${(props) => (props.theme.key == 'whipsnade' ? '200px' : undefined )};
    }
    ${mediaQueries.lg} {
      width: 385px;
      height: 385px;
      rect {
        width: ${(props) => (props.theme.key == 'whipsnade' ? '385px' : undefined )};
        height: ${(props) => (props.theme.key == 'whipsnade' ? '385px' : undefined )};
      }
    }
    &.left {
      top: -25.625px; /* static value as was moving too much with % */
      left: -117px; /* static value as was moving too much with % */
      g, path {
        fill: ${colors.honeyBee};
      }
      ${mediaQueries.md} {
        top: -23.859px; /* static value as was moving too much with % */
        left: -75.188px; /* static value as was moving too much with % */
      }
      ${mediaQueries.lg} {
        top: -70.734px; /* static value as was moving too much with % */
        left: -90.875px; /* static value as was moving too much with % */
      }
    }
    &.right {
      right: -95.391px; /* static value as was moving too much with % */
      bottom: -49.547px; /* static value as was moving too much with % */
      g, path {
        fill: ${(props) => (
          props.theme.key == 'london'
            ? colors.gecko
            : colors.tiger
        )};
      }
      ${mediaQueries.md} {
        right: -75.188px; /* static value as was moving too much with % */
        bottom: -80.125px; /* static value as was moving too much with % */
      }
      ${mediaQueries.lg} {
        right: -126.641px; /* static value as was moving too much with % */
        bottom: -58.938px; /* static value as was moving too much with % */
      }
    }
  }
`;
