import styled from 'styled-components';
import {buttonTemplate, colors, mediaQueries} from '@/theme/lib';

export const StyledInfoBlockLink = styled('div')`
  ${buttonTemplate}
  z-index: 1;
  padding: 1.25rem 2rem;
  border-radius: 20px;
  cursor: pointer;
  grid-column: 1 / span 5;
  text-transform: none;
  max-width: initial;
  text-align: left;
  ${mediaQueries.md} {
    padding: 1.25rem 1rem;
    grid-column: auto / span 2;
    display: flex;
    align-items: center;
    justify-content: center;
    &.large {
      grid-column: auto / span 3;
    }
  }
  ${mediaQueries.lg} {
    padding: 2.125rem 1rem;
    &.large {
      grid-column: auto / span 4;
    }
    &:first-child, &:nth-child(4n) {
      grid-column-start: 2;
    }
  }
  & > * {
    vertical-align: middle;
    display: inline-block;
  }
  &.even {
    background-color: ${(props) => props.theme.key === 'london' ? colors.gecko : colors.tiger};
    svg path {
      fill: ${colors.honeyBee};
    }
    &:nth-child(even) {
      background-color: ${colors.honeyBee};
      svg path {
        fill: ${(props) => props.theme.key === 'london' ? colors.gecko : colors.tiger};
      }
    }
    &:hover {
      border-color: ${colors.honeyBee};
      &:nth-child(even) {
        border-color: ${(props) => props.theme.key === 'london' ? colors.gecko : colors.tiger};
      }
    }
  }
  ${mediaQueries.md} {
    background-color: ${colors.honeyBee};
    svg path {
      fill: ${(props) => props.theme.key === 'london' ? colors.gecko : colors.tiger};
    }
    &.large {
      background-color: ${(props) => props.theme.key === 'london' ? colors.gecko : colors.tiger};
      svg path {
        fill: ${colors.honeyBee};
      }
      a {
        max-width: 80%;
      }
    }
    &:hover {
      border-color: ${(props) => props.theme.key === 'london' ? colors.gecko : colors.tiger};
      &.large {
        border-color: ${colors.honeyBee};
      }
    }
  }
  a {
    color: ${colors.black};
    margin-left: 1rem;
    max-width: 70%;
    line-height: 1.5rem;
    white-space: pre-wrap;
    ${mediaQueries.md} {
      line-height: 2rem;
    }
    ${mediaQueries.lg} {
      font-size: 1.26rem;
    }
    &:hover {
      text-decoration: none;
    }
  }
`;
