import styled from 'styled-components';
import {grid, mediaQueries} from '@/theme/lib';

export const StyledInfoBlock = styled('section')`
  position: relative;
  overflow-x: clip;
  margin-bottom: 3rem;
  .infoBlock {
    padding: 1.75rem 1.125rem;
    ${grid}
    ${mediaQueries.md} {
      padding: 1.75rem 2rem;
    }
    ${mediaQueries.lg} {
      gap: 1rem;
      padding: 4.25rem 0;
      margin-bottom: 4rem;
    }
  }
`;
