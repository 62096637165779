import {ReactElement} from 'react';

import AccessIcon from './assets/Accessibility.svg';
import AlertIcon from './assets/Alert.svg';
import CalendarIcon from './assets/Calendar.svg';
import ClockIcon from './assets/Clock.svg';
import InfoIcon from './assets/Info.svg';
import LocationIcon from './assets/Location.svg';
import ParkingIcon from './assets/Parking.svg';
import TicketIcon from './assets/Ticket.svg';

type InfoBlockIconProps = {
  icon: string;
};

export default function InfoBlockIcon({
  icon
}: InfoBlockIconProps): ReactElement {

  if (!icon) {
    return <></>;
  }

  switch (icon) {
    case 'access':
      return <AccessIcon />;
    case 'alert':
      return <AlertIcon />;
    case 'calendar':
      return <CalendarIcon />;
    case 'clock':
      return <ClockIcon />;
    case 'info':
      return <InfoIcon />;
    case 'location':
      return <LocationIcon />;
    case 'car':
      return <ParkingIcon />;
    case 'ticket':
      return <TicketIcon />;
    default:
      return <></>;
  }
}
